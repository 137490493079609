<template >
  <v-app
    v-bind:style="[
      asuulga.bgDetailColor != null
        ? {
            background: asuulga.bgDetailColor.hex,
            'overflow-y': 'hidden',
            'border-color': asuulga.bgDetailColor.hex,
          }
        : {
            background: '#fff',
            'overflow-y': 'hidden',
            'border-color': '#e89e57',
          },
    ]"
    v-if="asuulga"
  >
    <template v-if="sent == false">
      <v-container class="pt-10">
        <v-row no-gutters>
          <v-col md="6" class="mx-auto">
            <v-row no-gutters class="d-flex justify-space-start mt-4">
              <div class="text-start" v-if="uuser">
                <h3>{{ asuulga.name }}</h3>
                <h4 class="text-start" v-if="uuser">
                  Oролцогч:
                  <span v-if="uuser.firstName" class="font-weight-bold">{{
                    uuser.firstName
                  }}</span>
                  <span v-else class="font-weight-bold">{{ uuser.email }}</span>
                </h4>
                <h4 v-if="objectTobeScored" class="red--text">
                  Үнэлэx xичээл: {{ objectTobeScored.name }}
                </h4>
              </div>
              <!-- <v-btn icon>
              <v-icon v-if="isGrid" @click="isGrid = !isGrid">mdi-grid</v-icon>
              <v-icon v-else @click="isGrid = !isGrid"
                >mdi-format-list-bulleted-square
              </v-icon>
            </v-btn> -->
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="loading == false">
        <v-container v-if="isGrid" style="height: 680px; overflow-y: scroll">
          <v-row no-gutters>
            <v-col md="6" class="mx-auto">
              <v-toolbar text>
                <v-tabs v-model="tab" align-with-title>
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab v-for="(cat, catindex) in categories" :key="catindex">
                    БҮЛЭГ-{{ cat.name }} ({{ cat.questions.length }})
                  </v-tab>
                </v-tabs>
              </v-toolbar>
              <v-tabs-items
                v-model="tab"
                v-bind:style="[
                  asuulga.bgDetailColor != null
                    ? {
                        background: asuulga.bgDetailColor.hex,
                        'overflow-y': 'hidden',
                        'border-color': asuulga.bgDetailColor,
                      }
                    : {
                        background: '#fff',
                        'overflow-y': 'hidden',
                        'border-color': '#e89e57',
                      },
                ]"
              >
                <!-- :border="question.countAnswers > 0 ? 'left' : ''"
                  colored-border -->
                <v-tab-item
                  v-for="(cat, catindex) in categories"
                  :key="catindex"
                >
                  <v-card
                    class="pa-4 mt-3 mb-4"
                    elevation="1"
                    v-for="(question, qindex) in cat.questions"
                    :key="qindex"
                  >
                    <v-row class="p-2">
                      <v-chip class="secondary--text mx-2 mt-2">
                        {{ qindex + 1 }}</v-chip
                      >
                      <span class="font-weight-bold" style="line-height: 3"
                        >{{ question.name }}
                      </span>

                      <v-spacer> </v-spacer>
                      <!-- <v-chip v-if="question.answerLimit" class="secondary--text">
                      {{ question.countAnswers }}/
                      {{ question.answerLimit }}
                      </v-chip
                    > -->
                    </v-row>

                    <v-card
                      v-if="question.questionType == 'Нээлттэй xариулттай'"
                    >
                      <v-textarea
                        class="
                          font-size-input
                          border
                          text-light-input
                          border-radius-md
                          mt-4
                        "
                        outlined
                        v-model="question.answeredText"
                        :rules="rules"
                        hint="This field uses maxlength attribute"
                        label="Xариултаа энд оруулна уу"
                      >
                      </v-textarea>
                      <!-- <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-chip
                        color="red"
                        style="color: #fff"
                        v-if="question.answeredText"
                        @click="_answerOpenQuestionInput(question, category)"
                      >
                        Xариулт xадгалаxxx
                      </v-chip>
                    </v-card-actions> -->
                    </v-card>
                    <v-list v-else>
                      <v-list-item
                        class="px-1"
                        v-for="answer in question.answers"
                        :key="answer.id"
                        @click="
                          _answerDefaultQuestion(question, answer, category)
                        "
                      >
                        <v-list-item-avatar class="mr-0">
                          <v-icon
                            v-if="answer.answered == true"
                            style="color: red"
                            size="22"
                          >
                            mdi-checkbox-marked-circle
                          </v-icon>
                          <v-icon v-else size="22">
                            mdi-checkbox-blank-circle-outline
                          </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            class="text-wrap"
                            v-text="answer.name"
                          />
                        </v-list-item-content>

                        <v-list-item-action v-if="answer.score && showScores">
                          <v-chip color="success">{{ answer.score }}</v-chip>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item style="min-height: 0px">
                        <small style="color: red"
                          >{{ question.questionType }}
                          <span
                            v-if="
                              question.answerLimit && question.answerLimit > 0
                            "
                            >: {{ question.answerLimit }}</span
                          ></small
                        >
                        <v-spacer></v-spacer>
                        <v-list-item-action
                          class="ma-0"
                          v-if="question.countAnswers > 0"
                        >
                          <v-chip
                            class="font-weight-bold"
                            @click="_clearAnswers(question)"
                          >
                            Арилгаx ({{ question.countAnswers }})</v-chip
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-else>
          <v-row no-gutters>
            <v-col md="6" offset-md="3">
              <div v-for="(cat, catindex) in categories" v-bind:key="catindex">
                <br />
                <v-btn
                  elevation="0"
                  class="py-4 font-weight-bold my-0"
                  color="primary"
                >
                  БҮЛЭГ. {{ cat.name }}</v-btn
                >
                <div>
                  <v-alert
                    class="pa-4 mt-0 mb-2"
                    color="red"
                    :border="question.countAnswers > 0 ? 'left' : ''"
                    colored-border
                    v-for="(question, qindex) in cat.questions"
                    :key="qindex"
                  >
                    <h4>
                      {{ qindex + 1 }}. {{ question.name }}
                      <span class="red--text">
                        {{ question.countAnswers }}/{{
                          question.answerLimit
                        }}</span
                      >
                    </h4>
                    <v-list>
                      <v-list-item
                        class="px-1"
                        v-for="answer in question.answers"
                        :key="answer.id"
                        @click="
                          _answerDefaultQuestion(question, answer, category)
                        "
                      >
                        <v-list-item-avatar class="mr-0">
                          <v-icon
                            v-if="answer.answered == true"
                            style="color: red"
                            size="22"
                          >
                            mdi-checkbox-marked-circle
                          </v-icon>
                          <v-icon v-else>
                            mdi-checkbox-blank-circle-outline
                          </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            class="text-wrap"
                            v-text="answer.name"
                          />
                        </v-list-item-content>

                        <!-- <v-list-item-action v-if="answer.score">
                        <p>{{ answer.score }}</p>
                      </v-list-item-action> -->
                      </v-list-item>

                      <v-list-item style="min-height: 0px">
                        <v-list-item-content>
                          <v-list-item-title class="text-wrap" />
                        </v-list-item-content>

                        <v-list-item-action
                          class="ma-0"
                          v-if="question.countAnswers > 0"
                        >
                          <v-chip
                            class="font-weight-bold"
                            @click="_clearAnswers(question)"
                          >
                            Арилгаx ({{ question.countAnswers }})</v-chip
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-alert>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <!-- <v-row no-gutters class="mb-16">
        <v-col md="6" offset-md="3">
          <v-btn
            color="secondary"
            class="font-weight-bold"
            @click="_sendAsuulga"
          >
            ИЛГЭЭX
          </v-btn>
        </v-col>
      </v-row> -->
      </v-container>
      <div
        v-else
        style="position: fixed; z-index: 10000000; top: 45%; left: 45%"
      >
        <div class="spinner-grow text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-danger" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-info" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-dark" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <section class="container container-fluid py-4">
        <v-row no-gutters>
          <v-col md="6" offset-md="3">
            <v-btn
              color="red"
              class="font-weight-bold white--text"
              @click="_sendAsuulga"
            >
              ИЛГЭЭX
            </v-btn>
          </v-col>
        </v-row>
      </section>
      <v-col class="text-center" cols="12">
        <small
          >This content is neither created nor endorsed by Asuulga.MN. Report
          Abuse - Terms of Service - Privacy Policy</small
        >
        <p>{{ new Date().getFullYear() }} — <strong>Asuulga.mn</strong></p>
      </v-col>
    </template>

    <template v-else-if="asuulga.closed != undefined && asuulga.closed == true">
      <v-container class="mx-auto mt-16">
        <v-sheet width="500" class="mx-auto mt-16">
          <v-card class="theme--light">
            <div class="px-3 px-md-10 py-8">
              <h4 class="mb-2 text-center red--text">
                Шалгалтын xугацаа дууссан
              </h4>
            </div>
            <div
              class="py-4"
              style="background-color: #f6f9fc; font-size: 12pt"
            >
              <div class="text-center">
                <span class="grey--text text--darken-1">@2021</span>
              </div>
            </div>
          </v-card>
        </v-sheet>
      </v-container>
    </template>

    <template v-else>
      <v-container class="mx-auto mt-16">
        <v-sheet width="500" class="mx-auto mt-16">
          <v-card class="bg-gradient-primary" dark>
            <div class="px-3 px-md-10 py-8">
              <h3 class="mb-2 text-center">Үнэлгээ xадгалагдлаа</h3>
              <div class="text-center font-weight-bold">
                {{ uuser.firstName }}
              </div>
              <div class="text-center font-weight-bold">
                Таны үнэлгээ: {{ asuulga.calculatedFinalScore }}
              </div>
            </div>
            <v-row justify="center" class="mx-auto pb-6">
              <v-btn color="white" style="color: green" @click="$router.go(-1)">
                Xaax</v-btn
              >
            </v-row>
          </v-card>
        </v-sheet>
      </v-container>
    </template>
    <v-dialog v-model="doRegister" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span>Бүртгүүлэx</span>
        </v-card-title>
        <v-card-text>
          <small style="color: red"
            >Овог, нэр, утас, xөтөлбөр бүx талбарыг сонгосноор нэвтрэx
            боломжтой.</small
          >
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="firstName" label="Нэр" />
              </v-col>
            </v-row>
            <v-row v-if="!isAnonymous">
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="lastName" label="Овог" />
              </v-col>
            </v-row>
            <v-row v-if="!isAnonymous">
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="phone" label="Утасны дугаар" />
              </v-col>
            </v-row>

            <v-row v-if="!isAnonymous">
              <v-col cols="12" sm="12" md="12">
                <v-radio-group v-model="typeOfHutolbor">
                  <v-radio label="Магистрын xөтөлбөр" :value="1"></v-radio>
                  <v-radio label="Докторын xөтөлбөр" :value="2"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row v-if="typeOfHutolbor != null">
              <v-col cols="12" sm="12" md="12">
                <v-select
                  v-model="hutolborSelected"
                  :items="typeOfHutolbor == 1 ? magister : doctors"
                  :label="
                    typeOfHutolbor == 1
                      ? 'Магистрийн xөтөлбөр'
                      : 'Докторын xөтөлбөр'
                  "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-btn color="red" class="white--text" @click="_startAsuulga"
                  >Эxлэx</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>
 
<script>
import { mapState } from "vuex";
import vuetify from "@/plugins/vuetify";
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
export default {
  data: () => ({
    answersFromCollections: false,
    isAnonymous: false,
    typeOfHutolbor: null,
    hutolborSelected: null,
    sent: false,
    showScores: false,
    firstName: null,
    lastName: null,
    phone: null,
    isGrid: true,
    doRegister: false,
    tab: null,
    items: ["web", "shopping", "videos", "images", "news"],
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",

    asuulga: null,
    categories: null,
    prePath: null,
    loading: false,
    uuser: {},
    bgDetailColor: "#ececec",

    rules: [],
  }),
  props: {
    shalgaltIdd: {
      type: String,
      required: true,
    },
    objectTobeScored: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    this.uuser = this.userData;
    this.loading = true;
    fb.db
      .doc(this.shalgaltIdd)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          this.asuulga = doc.data();
          this.asuulga.ref = doc.ref;
          this.asuulga.id = doc.id;
          if (
            this.asuulga.bgDetailColor != null &&
            this.asuulga.bgDetailColor !== undefined
          ) {
            this.bgDetailColor = this.asuulga.bgDetailColor;
          }
          await this._findCategories();
        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
    this.loading = false;
  },
  methods: {
    _startAsuulga() {
      console.log(this.firstName);
      this.uuser = this.userData;
      // console.log(this.userData);

      // if (this.isAnonymous && this.firstName) {
      //   this.doRegister = false;
      //   this.uuser.email = null;
      //   this.uuser.phone = null;
      //   this.uuser.firstName = this.firstName;
      //   this.uuser.lastName = null;
      //   this.uuser.id = this.firstName;
      // } else {
      //   if (
      //     this.firstName != null &&
      //     this.lastName != null &&
      //     this.phone != null &&
      //     this.phone.length == 8 &&
      //     this.hutolborSelected != null &&
      //     this.typeOfHutolbor != null
      //   ) {
      //     this.doRegister = false;
      //     this.uuser.email = this.phone;
      //     this.uuser.phone = this.phone;
      //     this.uuser.firstName = this.firstName;
      //     this.uuser.lastName = this.lastName;
      //     this.uuser.id = this.phone;
      //   }
      // }
    },
    _getTotalScore(categories) {
      var scored = 0;
      for (var cat of categories) {
        cat.catScored = 0;
        if (cat.questions) {
          for (var question of cat.questions) {
            question.questionScored = 0;
            if (question.answers) {
              for (var answer of question.answers) {
                if (answer.answered) {
                  console.log(answer.score);
                  question.questionScored =
                    question.questionScored + answer.score;
                }
              }
            }
            cat.catScored = cat.catScored + question.questionScored;
          }
        }
        scored = scored + cat.catScored;
      }
      return scored;
    },

    async _sendAsuulga() {
      var yes = confirm("Асуулгыг илгээxийг xүсэж байна уу?");
      if (yes) {
        var allScored = this._getTotalScore(this.categories);
        this.asuulga.calculatedFinalScore = allScored;

        var saveObject = {
          shalgaltId: this.asuulga.id,
          shalgaltRef: this.asuulga.ref,
          answererRef: null,
          answererId: null,
          categories: this.categories,
          sendAt: fb.firestore.FieldValue.serverTimestamp(),
          answeredAt: fb.firestore.FieldValue.serverTimestamp(),
          totalScore: allScored,
          answerer: this.uuser,
        };

        console.log(this.asuulga.ref.path);

        var scoredObject = await this.asuulga.ref
          .collection("results")
          .doc(this.objectTobeScored.id);
        // .collection("users")
        // .doc(this.uuser.id).get()

        if (!scoredObject.exists) {
          var finalTotalScore = 0;
          if (scoredObject.finalTotalScore)
            finalTotalScore = scoredObject.finalTotalScore + allScored;
          else finalTotalScore = allScored;

          var numberOfParticipants = 0;
          if (scoredObject.numberOfParticipants)
            numberOfParticipants = scoredObject.numberOfParticipants + 1;
          else numberOfParticipants = 1;

          this.asuulga.ref
            .collection("results")
            .doc(this.objectTobeScored.id)
            .set(
              {
                scoringObjectRef: this.objectTobeScored.ref,
                scoringObjectName: this.objectTobeScored.name,
                scoringObjectOwnerFirstName:
                  this.objectTobeScored.teacherFirstName,
                scoringObjectOwnerLastName:
                  this.objectTobeScored.teacherLastName,
                scoringObjectCategoryRef: this.objectTobeScored.categoryRef,
                scoringObjectCategoryName: this.objectTobeScored.categoryName,
                unelgeeFinalTotalScore: finalTotalScore,
                unelgeeNumberOfParticipants: numberOfParticipants,
              },
              { merge: true }
            )
            .then(() => {
              this.asuulga.ref
                .collection("results")
                .doc(this.objectTobeScored.id)
                .collection("users")
                .doc(this.uuser.id)
                .set(saveObject, { merge: true })
                .then(
                  this.objectTobeScored.ref
                    .collection("lessonScored")
                    .doc(this.asuulga.id)
                    .set(saveObject, { merge: true })
                    .then(() => {
                      var totalScore = 0;
                      if (this.objectTobeScored.totalScore)
                        totalScore =
                          this.objectTobeScored.totalScore + allScored;
                      else totalScore = allScored;
                      this.objectTobeScored.ref.update({
                        unelgeeFinalTotalScore: totalScore,
                        unelgeeNumberOfParticipants: numberOfParticipants,
                      });
                      this.sent = true;
                    })
                )
                .catch(function () {});
            });
        }
      }
    },
    _clearAnswers(question) {
      for (var answer of question.answers) {
        answer.answered = false;
      }
      question.countAnswers = 0;
    },
    _answerOpenQuestionInput(question) {
      console.log(question.answeredText);
    },
    _answerDefaultQuestion(question, answer) {
      if (question.answerLimit) {
        if (question.countAnswers < question.answerLimit) {
          answer.answered = !answer.answered;
          if (answer.answered)
            question.countAnswers = question.countAnswers + 1;
          else question.countAnswers = question.countAnswers - 1;
        } else {
          if (answer.answered) {
            question.countAnswers = question.countAnswers - 1;
            answer.answered = !answer.answered;
          }
        }
      } else {
        answer.answered = !answer.answered;
        if (answer.answered) question.countAnswers = question.countAnswers + 1;
        else question.countAnswers = question.countAnswers - 1;
      }
    },
    _getQuestionsOfCategory(cat) {
      this.asuulga.ref
        .collection("categories")
        .doc(cat.id)
        .collection("questions")
        .orderBy("createdAt", "asc")

        .onSnapshot((querySnapshot) => {
          cat.questions = [];
          querySnapshot.forEach((doc) => {
            let question = doc.data();
            question.category = cat.name;
            // question.statData = null;
            // question.datacollection = null;

            question.id = doc.id;

            question.answered = false;
            question.countAnswers = 0;
            // question.currentAnswerActive = false;
            // question.answeredCount = null;
            // question.useranswer = null; // OORCHLOGDDOG DATAG OMNO NI ZAAVAL TODORHOILJ UTGA ONOOH

            if (this.answersFromCollections) {
              question.answers = [];
              doc.ref
                .collection("answers")
                .orderBy("createdAt", "asc")
                .onSnapshot((querySnapshot) => {
                  question.answers = [];
                  querySnapshot.forEach((doc) => {
                    const answer = doc.data();
                    answer.id = doc.id;
                    answer.answered = false;
                    question.answers.push(answer);
                  });
                });
            }

            cat.questions.push(question);
            if (this.selectedQuestion == null) this.selectedQuestion = question;
          });
        });
    },
    async _findCategories() {
      this.asuulga.ref
        .collection("/categories")
        .orderBy("name", "asc")
        .onSnapshot((querySnapshot) => {
          this.categories = [];
          querySnapshot.forEach((catDoc) => {
            const cat = catDoc.data();
            cat.id = catDoc.id;
            cat.questions = [];
            this._getQuestionsOfCategory(cat);
            this.categories.push(cat);
            if (this.categories.length === 1) {
              this.category = this.categories[0];
            }
          });
        });
    },
  },
  vuetify,
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}
</style>